export const englishTagsSuggestionData = [
  "TV",
  "baby",
  "baby_crying",
  "baby_talking",
  "barking",
  "beep",
  "bell",
  "bird_squawk",
  "breathing",
  "buzz",
  "buzzer",
  "child",
  "child_crying",
  "child_laughing",
  "child_talking",
  "child_whining",
  "child_yelling",
  "children",
  "children_talking",
  "children_yelling",
  "chiming",
  "clanging",
  "clanking",
  "click",
  "clicking",
  "clink",
  "clinking",
  "cough",
  "dishes",
  "door",
  "footsteps",
  "gasp",
  "groan",
  "hiss",
  "horn",
  "hum",
  "inhaling",
  "laughter",
  "meow",
  "motorcycle",
  "music",
  "noise",
  "nose_blowing",
  "phone_ringing",
  "popping",
  "pounding",
  "printer",
  "rattling",
  "ringing",
  "rustling",
  "scratching",
  "screeching",
  "sigh",
  "singing",
  "siren",
  "smack",
  "sneezing",
  "sniffing",
  "snorting",
  "squawking",
  "squeak",
  "static",
  "swallowing",
  "talking",
  "tapping",
  "throat_clearing",
  "thumping",
  "tone",
  "tones",
  "trill",
  "tsk",
  "typewriter",
  "ugh",
  "wheezing",
  "whispering",
  "whistling",
  "yawning",
  "yelling",
];

export const malayalamTagsSuggestionData = [
  "റ്റി.വി.",
  "കുഞ്ഞ്",
  "കുഞ്ഞ് കരയുന്നു",
  "കുഞ്ഞ് സംസാരിക്കുന്നു",
  "കുരയ്ക്കുന്നു",
  "ബീപ്പ്",
  "മണി",
  "പക്ഷിക്കരച്ചിൽ",
  "ശ്വാസോച്ഛ്വാസം",
  "മൂളൽ",
  "ബസർ",
  "കുട്ടി",
  "കുട്ടി കരയുന്നു",
  "കുട്ടി ചിരിക്കുന്നു",
  "കുട്ടി സംസാരിക്കുന്നു",
  "കുട്ടി ചിണുങ്ങുന്നു",
  "കുട്ടി അലറുന്നു",
  "കുട്ടികൾ",
  "കുട്ടികൾ സംസാരിക്കുന്നു",
  "കുട്ടികൾ അലറുന്നു",
  "മണിമുഴക്കുന്നു",
  "കിലുക്കുന്നു",
  "ലോഹത്തിന്റെ ശബ്ദം",
  "ക്ലിക്കുചെയ്യുക",
  "ക്ലിക്കു ചെയ്യുന്നു",
  "കലമ്പൽ",
  "കിലുകിലുക്കം",
  "ചുമ",
  "പാത്രങ്ങൾ",
  "വാതിൽ",
  "ചവിട്ടുപടികൾ",
  "കിതയ്ക്കുക",
  "ഞരക്കം",
  "സീൽക്കാരം",
  "ഹോൺ",
  "മൂളുക",
  "ശ്വാസമെടുക്കുന്നു",
  "ചിരി",
  "മ്യാവൂ",
  "മോട്ടോർ സൈക്കിൾ",
  "സംഗീതം",
  "ശബ്ദം",
  "മൂക്കുചീറ്റൽ",
  "ഫോൺ ബെല്ലടിക്കുന്നു",
  "പൊടിക്കുന്നു",
  "പ്രിന്റർ",
  "ചിലമ്പൽ",
  "ചെയ്യുന്നു",
  "മർമ്മരം",
  "ചുരണ്ടുന്നു",
  "പരുക്കൻ ശബ്ദം",
  "നെടുവീർപ്പ്",
  "പാടുന്നു",
  "സൈറൺ",
  "തുമ്മുന്നു",
  "മണക്കുന്നു",
  "ചീറുന്നു",
  "ആക്രോശിക്കുന്നു",
  "കാറൽ",
  "നിശ്ചലം",
  "വിഴുങ്ങുന്നു",
  "സംസാരിക്കുന്നു",
  "മെല്ലെ തട്ടുന്നു",
  "മുരടനക്കുന്നു",
  "ഉച്ചത്തിൽ തട്ടുന്നു",
  "റ്റോൺ",
  "റ്റോണുകൾ",
  "വിറയൽ ശബ്ദം",
  "റ്റൈപ്പ് റൈറ്റർ",
  "ഉഹ്",
  "വിമ്മിട്ടപ്പെടുന്നു",
  "അടക്കിപ്പറയുന്നു",
  "ചൂളമിടുന്നു",
  "കോട്ടുവായിടുന്നു",
  "ആക്രോശിക്കുന്നു",
];

export const bengaliTagsSuggestionData = [
  "টিভি/দূরদর্শন",
  "শিশু",
  "শিশু_কাঁদছে",
  "শিশু_কথা বলছে",
  "ঘেউঘেউ",
  "বিপ",
  "ঘন্টা",
  "পাখি_ডাকছে",
  "শ্বাসপ্রশ্বাস",
  "গুঞ্জন",
  "ভেঁপু",
  "বাচ্চা",
  "বাচ্চা_কাঁদছে",
  "বাচ্চা_হাসছে",
  "বাচ্চা_কথা বলছে",
  "বাচ্চা_ঘ্যানঘ্যান করছে",
  "বাচ্চা_চেঁচাচ্ছে",
  "বাচ্চারা",
  "বাচ্চারা_কথা বলছে",
  "বাচ্চারা_চেঁচাচ্ছে",
  "টুংটাং",
  "ঠংঠং",
  "ঠংঠং",
  "ক্লিক",
  "ক্লিক করা",
  "ঠুংঠুং",
  "ঠুংঠুং করা",
  "কাশি",
  "বাসন",
  "দরজা",
  "পদধ্বনি",
  "খাবি খাওয়া",
  "গোঙানো",
  "হিস্\u200cহিস্\u200c",
  "হর্ন",
  "গুন্\u200cগুন্\u200c",
  "শ্বাস নেওয়া",
  "হাসি",
  "ম্যাও",
  "মোটরসাইকেল",
  "সঙ্গীত",
  "আওয়াজ",
  "নাক_ঝাড়ছে",
  "ফোন_বাজছে",
  "ফাটছে",
  "পিষছে",
  "প্রিন্টার",
  "খড়খড়",
  "রিংরিং",
  "খসখস",
  "আঁচড় কাটছে",
  "চিৎকার করছে",
  "দীর্ঘশ্বাস",
  "গাইছে",
  "সাইরেন",
  "ঠাস",
  "হাঁচি",
  "শুঁকছে",
  "নাসিকাধ্বনি",
  "কা কা করছে",
  "চিঁ চিঁ করছে",
  "অচলতড়িৎ",
  "গিলছে",
  "কথা বলছে",
  "ঠক ঠক করছে",
  "গলা_খাঁকরাচ্ছে",
  "থপ থপ করছে",
  "স্বর",
  "স্বরগুলি",
  "কম্পনধ্বনি",
  "চুকচুক",
  "টাইপরাইটার",
  "ইস",
  "শ্বাসকষ্ট",
  "ফিসফিস",
  "শিস",
  "হাই তুলছে",
  "চেঁচাচ্ছে",
];

export const sanskritTagsSuggestionData = [
  "टि.वि.",
  "शिशुः",
  "शिशोः रोदनम्",
  "कलभाषणम्",
  "बुक्कनम्",
  "बीप्-रवः",
  "घण्टा",
  "पक्षिणः कूजनम्",
  "श्वासोच्छ्वासः",
  "गुञ्जनम्",
  "नादकम्",
  "बालः",
  "बालस्य रोदनम्",
  "बालस्य हसनम्",
  "बालस्य भाषणम्",
  "बालस्य विलपनम्",
  "बालस्य कूजनम्",
  "बालाः",
  "बालानां भाषणम्",
  "बालानां कूजनम्",
  "वादनम्",
  "ध्वननम्",
  "क्वणनम्",
  "क्लिक्",
  "क्लिक्-करणम्",
  "शिञ्जनम्",
  "शिञ्जनम्",
  "कासः",
  "पात्राणि",
  "द्वारम्",
  "चरणम्",
  "निश्श्वसितम्",
  "आर्तस्वरः",
  "फूत्करणम्",
  "सूचनाध्वनिः",
  "गुञ्जितम्",
  "उच्छ्वसनम्",
  "हसनम्",
  "मार्जारस्य कूजनम्",
  "द्विचक्रिका",
  "गीतम्",
  "रवः",
  "नासावमार्जनम्",
  "दूरवाणि-शब्दः",
  "उत्पुटनम्",
  "चूर्णनम्",
  "उट्टङ्ककः",
  "रणनम्",
  "नदनम्",
  "पर्णस्वनम्",
  "क्षुरणम्",
  "आक्रन्दनम्",
  "निश्श्वासः",
  "गायनम्",
  "सैरन्-ध्वनिः",
  "आस्फारणम्",
  "क्षवणम्",
  "क्लन्दनम्",
  "उच्छ्वसितम्",
  "कूजनम्",
  "रिङ्गणम्",
  "तटस्थम्",
  "भक्षणम्",
  "भाषितम्",
  "पटपटितम्",
  "कण्टशोधितम्",
  "घट्टनम्",
  "स्वरः",
  "स्वराः",
  "स्वरकम्पनम्",
  "धिक्",
  "मुद्रकम्",
  "अलम्",
  "प्रतिक्षुतम्",
  "कर्णेकथनम्",
  "गुञ्जनम्",
  "जृम्भितम्",
  "चित्करणम्",
];

export const marathiTagsSuggestionData = [
  "टीव्ही",
  "बाळ",
  "बाळ_रडताना",
  "बाळ_बोलताना",
  "भुंकताना",
  "बीप",
  "घंटा",
  "पक्षाचा_कर्कश आवाज",
  "श्वासोच्छ्वास",
  "घोंघावणे",
  "बझर",
  "मूल",
  "मूल_रडताना",
  "मूल_हसताना",
  "मूल_बोलताना",
  "मूल_किरकिरताना",
  "मूल_किंचाळताना",
  "मुले",
  "मुले_बोलताना",
  "मुले_किंचाळताना",
  "झंकार",
  "घणघाणाट",
  "खणखणाट",
  "टिकटिक",
  "टिकटिक होताना",
  "किणकिण",
  "किणकिण होताना",
  "खोकला",
  "पदार्थ",
  "दरवाजा",
  "पावले",
  "धाप लागणे",
  "कण्हणे",
  "फुत्कार",
  "कर्णा",
  "गुंजारव",
  "श्वास घेताना",
  "हशा",
  "म्यांव",
  "फटफटी",
  "संगीत",
  "कलकलाट",
  "नाक_शिंकरताना",
  "फोन_वाजताना",
  "फट आवाज",
  "कांडण",
  "मुद्रण यंत्र",
  "खडखड",
  "घंटानाद",
  "सळसळ",
  "ओरखडताना",
  "खरखरताना",
  "नि:श्वास",
  "गाताना",
  "भोंगा",
  "चापट",
  "शिंकताना",
  "हुंगताना",
  "उपहास",
  "कर्कश आवाज काढताना",
  "ची ची आवाज",
  "स्थिर",
  "गिळताना",
  "बोलताना",
  "टपटप",
  "घसा_खाकरणे",
  "दणदण आवाज",
  "स्वर",
  "अनेक स्वर",
  "कंपयुक्त आवाज",
  "नापसंतीचा उद्गार",
  "टंकन यंत्र",
  "तिरस्काराचा उद्गार",
  "घरघर",
  "कुजबूज करताना",
  "शिट्टी मारताना",
  "जांभई देताना",
  "किंचाळताना",
];

export const kannadaTagsSuggestionData = [
  "ಟಿ.ವಿ.",
  "ಮಗು",
  "ಮಗು_ಅಳುತ್ತಿರುವುದು",
  "ಮಗು_ಮಾತನಾಡುತ್ತಿರುವುದು",
  "ಬೊಗಳುತ್ತಿರುವುದು",
  "ಬೀಪ್",
  "ಗಂಟೆ",
  "ಪಕ್ಷಿಯ_ಕೂಗು",
  "ಉಸಿರಾಟ",
  "ಝೇಂಕಾರ",
  "ಗುಂಜನ",
  "ಮಗು",
  "ಮಗು_ಅಳುತ್ತಿರುವುದು",
  "ಮಗು_ನಗುತ್ತಿರುವುದು",
  "ಮಗು_ಮಾತನಾಡುತ್ತಿರುವುದು",
  "ಮಗು_ಕುಂಯ್ಗುಡುತ್ತಿರುವುದು",
  "ಮಗು_ಕಿರುಚುತ್ತಿರುವುದು",
  "ಮಕ್ಕಳು",
  "ಮಕ್ಕಳು_ಮಾತನಾಡುತ್ತಿರುವುದು",
  "ಮಕ್ಕಳು_ಕಿರುಚಾಡುತ್ತಿರುವುದು",
  "ಕಿಂಕಿಣಿಸುವ ಸದ್ದು",
  "ಘಣಿಲ್ ಸದ್ದು",
  "ಝಣಝಣ ಸದ್ದು",
  "ಕ್ಲಿಕ್",
  "ಕ್ಲಿಕ್ಕಿಸುವ ಸದ್ದು",
  "ಟಿಂಟಿಣಿ",
  "ಟಿಂಟಿಣಿಸುವ ಸದ್ದು",
  "ಕೆಮ್ಮು",
  "ಪಾತ್ರೆಗಳು",
  "ಬಾಗಿಲು",
  "ಹೆಜ್ಜೆ",
  "ಏದುಸಿರು",
  "ಮುಲುಗು",
  "ಬುಸ್ಸ್",
  "ಭೋಂಪು",
  "ಗುನುಗು",
  "ಉಚ್ಛ್ವಾಸ",
  "ನಗು",
  "ಮಿಯಾಂವ್",
  "ಮೋಟಾರುವಾಹನ",
  "ಸಂಗೀತ",
  "ಗದ್ದಲ",
  "ಮೂಗು_ಒರೆಸಿಕೊಳ್ಳುತ್ತಿರುವುದು",
  "ಫೋನ್_ರಿಂಗಣಿಸುತ್ತಿರುವುದು",
  "ಪಾಪ್ ಸದ್ದು",
  "ಕುಟ್ಟುವ ಸದ್ದು",
  "ಮುದ್ರಣಯಂತ್ರ",
  "ಲಟಲಟ ಸದ್ದು",
  "ರಿಂಗಣ",
  "ಮರ್ಮರ",
  "ಕೆರೆಯುತ್ತಿರುವುದು",
  "ಚೀತ್ಕಾರ",
  "ನಿಟ್ಟುಸಿರು",
  "ಹಾಡುತ್ತಿರುವುದು",
  "ಸೈರನ್ನು",
  "ಚಪ್ಪರಿಕೆ",
  "ಸೀನುತ್ತಿರುವುದು",
  "ಮೂಸುತ್ತಿರುವುದು",
  "ಘೂಂಕರಿಸುತ್ತಿರುವುದು",
  "ಚೀರಲು ಸದ್ದು",
  "ಕಿಚುಗುಟ್ಟುತ್ತಿರುವುದು",
  "ನಿಶ್ಚಲ",
  "ನುಂಗುತ್ತಿರುವುದು",
  "ಮಾತನಾಡುತ್ತಿರುವುದು",
  "ತಟ್ಟುವ ಸದ್ದು",
  "ಗಂಟಲು_ಸರಿಮಾಡಿಕೊಳ್ಳುತ್ತಿರುವುದು",
  "ಬಡಿತದ ಸದ್ದು",
  "ಧ್ವನಿ",
  "ಧ್ವನಿಗಳು",
  "ನಡುಗು ಧ್ವನಿ",
  "ಪ್ಚ್",
  "ಟೈಪ್ ರೈಟರ್",
  "ಛೆ",
  "ಉಬ್ಬಸದ ಶಬ್ದ",
  "ಪಿಸುಗುಡುತ್ತಿರುವುದು",
  "ಸಿಳ್ಳೆ ಸದ್ದು",
  "ಆಕಳಿಸುತ್ತಿರುವುದು",
  "ಅರಚುತ್ತಿರುವುದು",
];

export const teluguTagsSuggestionData = [
  "టీ.వీ.",
  "శిశువు",
  "శిశువు_ఏడవటం",
  "శిశువు_మాట్లాడటం",
  "మొరగటం",
  "బీప్",
  "గంట",
  "పిట్ట_అరుపు",
  "శ్వాసించటం",
  "బజ్",
  "బజ్జర్",
  "బిడ్డ",
  "బిడ్డ_ఏడవటం",
  "బిడ్డ_నవ్వటం",
  "బిడ్డ_మాట్లాడటం",
  "బిడ్డ_కూనిరాగాలు తీయటం",
  "బిడ్డ_అరవటం",
  "పిల్లలు",
  "పిల్లలు_మాట్లాడటం",
  "పిల్లలు_అరవటం",
  "ధ్వనించటం",
  "గణగణమనడం",
  "లోహపు ధ్వనులు చేయటం",
  "క్లిక్",
  "క్లిక్\u200cమనటం",
  "క్లింక్",
  "క్లింక్\u200cమనటం",
  "దగ్గు",
  "పాత్రలు",
  "తలుపు",
  "అడుగులు",
  "ఎగశ్వాస ",
  "మూలుగు",
  "బుస్సుమను",
  "హారన్",
  "ఝంకారం",
  "ఉచ్ఛ్వాసం",
  "నవ్వు",
  "మియావ్",
  "మోటార్\u200cసైకిల్",
  "సంగీతం",
  "శబ్దం",
  "ముక్కు చీదటం",
  "ఫోను మోగటం",
  "పేలుడు శబ్దం",
  "కొట్టడం",
  "ప్రింటర్",
  "కటకటమనటం",
  "మ్రోగటం",
  "గలగలమనటం",
  "గోకటం",
  "కీచుగా అరవటం",
  "నిట్టూర్పు",
  "పాడటం",
  "సైరన్",
  "కొట్టటం",
  "తుమ్మటం",
  "వాసన పీల్చటం",
  "సకిలింత",
  "పక్షి శబ్దం",
  "కీచుమనటం",
  "విద్యుత్తు వల్ల శబ్దం",
  "మింగటం",
  "మాట్లాడటం",
  "తట్టటం",
  "గొంతు సవరించుకోవటం",
  "గుద్దటం",
  "స్వరం",
  "స్వరాలు",
  "వణికే గొంతు",
  "ప్చ్",
  "టైప్\u200cరైటర్",
  "చిరాకు",
  "గుర్రుమని శ్వాస",
  "గుసగుస",
  "ఈల వేయటం",
  "ఆవలింత",
  "కేకలు పెట్టటం",
];

export const sindhiTagsSuggestionData = ["टी.वी."];

export const bodoTagsSuggestionData = [
  "टि.भि.",
  "गोरलै",
  "गोरलै_गाबदों",
  "गोरलै_रायलायदों",
  "सोंदों",
  "बिप",
  "घन्टा",
  "दाउ_गाबदों",
  "हां लादों",
  "थेर",
  "थेरग्रा",
  "खुदिया",
  "खुदिया_गाबदों",
  "खुदिया_मिनिदों",
  "खुदिया_रायलायदों",
  "खुदिया_खुद्रिदों",
  "खुदिया_होसिख्रावदों",
  "खुदियाफोर",
  "खुदियाफोर_रायलायदों",
  "खुदियाफोर_होसिख्रावदों",
  "थिं थिं",
  "थं थं",
  "ख्रें ख्रें",
  "टिक टिक",
  "टिक टिक गाबदों",
  "थुंथुं-थांथां",
  "थिं थिंं गाबदों",
  "गुजुस्रेम",
  "थोरसिफोर",
  "दरजा",
  "आगाननि सोदोब",
  "हांमा सुर",
  "रोनाव",
  "फुस फुस",
  "हर्न दाम",
  "ब्रुं ब्रुं",
  "हां लानाय",
  "मिनि",
  "मेउ मेउ",
  "मटर साइकेल",
  "देंखो",
  "दावराव",
  "गन्थं गाबनाय",
  "फ'न रिंदों",
  "फ्रात ",
  "सौनाय",
  "प्रिन्टार",
  "खख्र-खाख्रा",
  "रिंनाय",
  "रिब रिब",
  "खुरनाय",
  "होसिख्राव",
  "हांमा सुर",
  "रोजाब",
  "साइरेन",
  "सोबा",
  "हारसिउ",
  "मोनामसु",
  "जुगुम",
  "बोराब",
  "सि सि खालाम",
  "दलर",
  "मन'",
  "रायलायदों",
  "बुज्रावदों",
  "गोदोना_स्रां खालामदों",
  "सौदों",
  "सुर",
  "देंखोफोर",
  "देंखो खम्फिनाय",
  "चि चि",
  "टाइपरायटार",
  "उस",
  "हां थोनो थोनो",
  "फुस फुस",
  "मुसुरदों",
  "सियायदों",
  "होख्रावदों",
];

export const assameseTagsSuggestionData = [
  "টি.ভি.",
  "কেঁচুৱা",
  "কেঁচুৱা_কান্দিছে",
  "কেঁচুৱা_কথা পাতিছে",
  "ভুকভুকনি",
  "বিপ",
  "ঘণ্টা",
  "চৰাই_চী চিঁয়নি",
  "শ্বাসক্ৰিয়া",
  "গুঞ্জন",
  "পেঁপা",
  "শিশু",
  "শিশু_কান্দিছে",
  "শিশু_হাঁহিছে",
  "শিশু_কথা পাতিছে",
  "শিশু_থেনথেনাইছে",
  "শিশু_চিঞৰিছে",
  "শিশুসকল",
  "শিশুসকল_কথা পাতিছে",
  "শিশুসকল_চিঞৰিছে",
  "ঝন ঝন",
  "ৰন জন",
  "ঘটং-মটং",
  "ক্লিক",
  "ক্লিক কৰা",
  "ঠনঠন",
  "ঠনঠননি",
  "কাহ",
  "কাঁহী",
  "দৰ্জা",
  "পদধ্বনি",
  "ফোঁপনি",
  "কেঁকনি",
  "ফোঁচ ফোঁচনি",
  "হৰ্ণ",
  "গুনগুন",
  "উশাহ লোৱা",
  "হাঁহি",
  "মেও মেও",
  "মটৰ চাইকেল",
  "সঙ্গীত",
  "কোলাহল",
  "নাক_ফোৰোহণি মাৰিছে",
  "ফোন_বাজিছে",
  "ফট কৰা",
  "খুন্দা",
  "প্ৰিণ্টাৰ",
  "খটখটনি",
  "ৰিং কৰিছে",
  "ফুচফুচনি",
  "আঁচোৰিছে",
  "কুৰুলিয়াইছে",
  "হুমুনিয়াহ কাঢ়া",
  "গাইছে",
  "ছাইৰেণ",
  "চৰিয়া",
  "হাঁচি মাৰিছে",
  "শুঙিছে",
  "ফোৰোহণি মাৰিছে",
  "কা কা কৰিছে",
  "চী চিঁয়নি কৰিছে",
  "স্থিৰ",
  "গিলিছে",
  "কথা পাতিছে",
  "টুকৰিয়াইছে",
  "গল_খোলোচা কৰিছে",
  "ঢকিয়াইছে",
  "স্বৰ",
  "স্বৰসমূহ",
  "স্বৰৰ কম্পন",
  "টাইপৰাইটাৰ",
  "ইচ",
  "সেহনি",
  "ফুচফুচাইছে",
  "সুহুৰি মাৰিছে",
  "হামিয়াইছে",
  "চিঞৰিছে",
];

export const tamilTagsSuggestionData = [
  "டிவி",
  "குழந்தை",
  "குழந்தை_அழுகிறது",
  "குழந்தை_பேசுகிறது",
  "நாய்_குரைக்கிறது",
  "பீப்",
  "மணி",
  "பறவை_கத்தும்_சப்தம்",
  "மூச்சுவிடும்_ஓசை",
  "தேனீ_ஓசை",
  "பஸ்ஸர்_ஓசை",
  "குழந்தை",
  "குழந்தை_அழுகிறது",
  "குழந்தை_சிரிக்கிறது",
  "குழந்தை_பேசுகிறது",
  "குழந்தை_புலம்புகிறது",
  "குழந்தை_கத்துகிறது",
  "குழந்தைகள்",
  "குழந்தைகள்_பேசுகிறார்கள்",
  "குழந்தைகள்_கத்துகிறார்கள்",
  "கடிகாரம்_அடிக்கிறது",
  "கிளிக்",
  "கிளிக்_ஓசை",
  "இருமல்",
  "பாத்திரங்கள்",
  "கதவு",
  "காலடி",
  "மூச்சுவாங்குகிறது",
  "முனகல்",
  "ஹாரன்",
  "மூச்சிழுத்தல்",
  "சிரிப்பு",
  "மியாவ்",
  "மோட்டர்சைக்கிள்",
  "இசை",
  "இரைச்சல்",
  "மூக்கு_உறிஞ்சுதல்",
  "போன்_அடிக்கிறது",
  "பிரிண்டர்",
  "மணி_அடிக்கிறது",
  "சலசலப்பு",
  "கீறல்",
  "கிரீச்",
  "பெருமூச்சு",
  "பாட்டு",
  "சைரன்",
  "தும்மல்",
  "மோப்பம்",
  "விழுங்கும்_ஓசை",
  "பேச்சு",
  "தட்டுதல்",
];

export const santaliTagsSuggestionData = [
  "ᱴᱤᱹ ᱵᱤ ",
  "ᱵᱟᱞᱮ ᱜᱤᱫᱽᱨᱟᱹ ",
  "ᱵᱟᱞᱮ ᱜᱤᱫᱽᱨᱟᱹᱭ_ᱨᱟᱜ ᱮᱫᱟ",
  "ᱥᱮᱛᱟᱭ ᱵᱷᱩᱜ ᱮᱫᱟ",
  "ᱜᱷᱟᱹᱱᱴᱤ",
  "ᱪᱮᱸᱲᱮ_ᱨᱟᱜ",
  "ᱥᱟᱦᱮᱸᱫ ",
  "ᱜᱤᱫᱽᱨᱟᱹ ",
  "ᱜᱤᱫᱽᱨᱟᱹᱭ_ᱨᱟᱜ ᱮᱫᱟ",
  "ᱜᱤᱫᱽᱨᱟᱹᱭ_ᱞᱟᱸᱫᱟᱭᱮᱫᱟ",
  "ᱜᱤᱫᱽᱨᱟᱹᱭ_ᱨᱚᱫᱚᱱ ᱠᱟᱱᱟ ",
  "ᱜᱤᱫᱽᱨᱟᱹ ᱠᱚ",
  "ᱜᱤᱫᱽᱨᱟᱹ ᱠᱚ_ᱨᱚᱲᱮᱫ ᱠᱟᱱᱟ ",
  "ᱠᱞᱤᱠ",
  "ᱠᱷᱩᱜ",
  "ᱛᱷᱟᱹᱨᱤ ᱠᱚ ",
  "ᱫᱩᱣᱟᱹᱨ ",
  "ᱟᱲᱟᱝ  ",
  "ᱟᱲᱟᱝ ᱠᱚ",
  "ᱛᱟᱨᱠᱚ ᱥᱟᱰᱮ ",
  "ᱴᱟᱭᱤᱯ ᱨᱟᱭᱴᱟᱨ",
  "ᱩᱦ",
  "ᱩᱫᱩᱨ",
  "ᱯᱷᱟᱥᱟᱨ ᱯᱷᱩᱥᱩᱨ",
  "ᱜᱚᱞ/ᱣᱩᱭᱥᱮᱞ",
  "ᱟᱸᱜᱚᱵ ᱮᱫᱟᱭ",
];

export const odiaTagsSuggestionData = [
  "ଟିଭି",
  "ଶିଶୁ/ପିଲା",
  "ଶିଶୁ କାନ୍ଦୁଛି",
  "ପିଲା କହୁଛି",
  "ଭୁକୁଛି",
  "ବିପ୍\u200c",
  "ଘଣ୍ଟି",
  "ପକ୍ଷୀ ଚିତ୍କାର",
  "ଶ୍ୱାସକ୍ରିୟା",
  "ଗୁଞ୍ଜନ",
  "ଘଣ୍ଟି",
  "ପିଲା",
  "ପିଲା କାନ୍ଦୁଛି",
  "ପିଲା ହସୁଛି",
  "ପିଲା କହୁଛି",
  "ପିଲାର କରୁଣ କ୍ରନ୍ଦନ",
  "ପିଲାର ଚିତ୍କାର",
  "ପିଲାମାନେ",
  "ପିଲାମାନେ କଥାହେଉଛନ୍ତି",
  "ପିଲାମାନେ ଚିତ୍କାର କରୁଛନ୍ତି",
  "ଘଣ୍ଟାଘ୍ୱନି",
  "ଠଣ୍\u200c ଠଣ୍\u200c ଶବ୍ଦ",
  "ଝଣ୍\u200c ଝଣ୍\u200c ଶବ୍ଦ",
  "ଟିକ୍\u200c ଟିକ୍\u200c ଶବ୍ଦ",
  "ଟିକ୍\u200c ଟିକ୍\u200c ଶବ୍ଦ କରୁଛି",
  "ଝଣ୍\u200c ଝଣ୍\u200c ଶବ୍ଦ",
  "ଝଣ୍\u200c ଝଣ୍\u200c ଶବ୍ଦ କରୁଛି",
  "କାଶ",
  "ବାସନ",
  "କବାଟ",
  "ପାଦଶବ୍ଦ",
  "ଧଇଁସଇଁ ହେବା",
  "ଯନ୍ତ୍ରଣାରେ ଚିତ୍କାର",
  "ସୁ ସୁ ଶବ୍ଦ",
  "ହର୍ନ",
  "ଗୁଣୁଗୁଣୁ ଶବ୍ଦ",
  "ଶ୍ୱାସ ନେବା",
  "ହାସ୍ୟଧ୍ୱନି",
  "ମ୍ୟାଉଁ ମ୍ୟାଉଁ ଶବ୍ଦ",
  "ମୋଟରସାଇକେଲ",
  "ସଙ୍ଗୀତ",
  "କୋଳାହଳ",
  "ନାକ ସଫାକରିବା",
  "ଫୋନ୍\u200c ବାଜିବା",
  "ଫୁଟିବା",
  "ଗୁଣ୍ଡକରିବା",
  "ପ୍ରିଣ୍ଟର",
  "ଖଡ଼୍\u200c ଖଡ଼୍\u200c ଶବ୍ଦ",
  "ବାଜିବା",
  "ଖସ୍\u200c ଖସ୍\u200c ଶବ୍ଦ",
  "ଆଞ୍ଚୁଡ଼ିବା / ରାମ୍ପୁଡ଼ିବା",
  "କର୍କଶ ଓ ଉଚ୍ଚ ସ୍ୱରରେ ଚିତ୍କାର",
  "ଦୀର୍ଘଶ୍ୱାସ",
  "ଗାଇବା",
  "ସାଇରନ୍\u200c",
  "ଚାପୁଡ଼ା",
  "ଛିଙ୍କିବା",
  "ଶୁଙ୍ଘିବା",
  "ଖିଙ୍କାରିଲା ପରି ଶବ୍ଦ",
  "ପକ୍ଷୀମାନଙ୍କର କର୍କଶ ସ୍ୱର",
  "ଚିଁ ଚିଁ ଶବ୍ଦ",
  "ସ୍ଥିର",
  "ଗିଳିପକାଇବା",
  "କହିବା",
  "ଠକ୍\u200cଠକ୍\u200c ଶବ୍ଦ",
  "ଗଳା ସଫାକରିବା",
  "ଧଡ୍\u200c ଧଡ଼୍\u200c କରିବା",
  "ସ୍ୱର",
  "ସ୍ୱରଗୁଡ଼ିକ",
  "କମ୍ପିତ ସ୍ୱର",
  "ଚ୍\u200c ଚ୍\u200c",
  "ଟାଇପରାଇଟର",
  "ଛିଃ",
  "ସଁ ସଁ ନିଶ୍ୱାସ",
  "ଫୁସ୍\u200cଫୁସ୍\u200c କରି କହିବା",
  "ସିଟି ମାରିବା",
  "ହାଇ ମାରିବା",
  "ପାଟି କରିବା",
];

export const hindiTagsSuggestionData = [
  "टी.वी.",
  "शिशु",
  "शिशु रो रहा है",
  "शिशु बोल रहा है",
  "भौंकना",
  "बीप",
  "घंटी",
  "पक्षी की चहचहाहट",
  "साँस लेना",
  "गुंजन",
  "बज़र",
  "बच्चा",
  "बच्चा रो रहा है",
  "बच्चा हँस रहा है",
  "बच्चा बोल रहा है",
  "बच्चा रिरिया रहा है",
  "बच्चा चिल्ला रहा है",
  "बच्चे",
  "बच्चे बोल रहे हैं",
  "बच्चे चिल्ला रहे हैं",
  "टनटनाहट",
  "ठनठनाहट",
  "झनझनाहट",
  "क्लिक",
  "क्लिक करना",
  "झंकार",
  "झंकार की आवाज़",
  "खाँसी",
  "बर्तन",
  "दरवाज़ा",
  "कदम",
  "हाँफना",
  "कराहना",
  "फुफकारना",
  "हॉर्न",
  "गुनगुनाना",
  "साँस लेना",
  "हँसी",
  "म्याऊँ",
  "मोटरसाइकिल",
  "संगीत",
  "शोर",
  "नाक साफ करना",
  "फोन बजने की आवाज",
  "फूटने की आवाज",
  "पीसना",
  "प्रिंटर",
  "खड़खड़ाहट",
  "खनखनाहट",
  "सरसराहट",
  "खरोंचना",
  "चीत्कार मारना",
  "आह भरना",
  "गाना",
  "साइरन",
  "चपत लगाना",
  "छींकना",
  "नाक सुड़कना",
  "नासिका ध्वनि",
  "कर्कश आवाज करना",
  "चीं चीं की आवाज करना",
  "(फोन, टी.वी. आदि की)कर्कश आवाज",
  "निगलना",
  "बात करना",
  "टप टप की आवाज",
  "गला खखारना",
  "थपथपाहट",
  "स्वर",
  "कई स्वर",
  "कंपनमय आवाज",
  "च् च्",
  "टाइपराइटर",
  "छिः",
  "घरघराहट",
  "फुसफुसाहट",
  "सीटी की आवाज",
  "उबासी लेना",
  "चिल्लाना",
];

export const noiseTags = {
  malayalam: {
    TV: "റ്റി.വി.",
    baby: "കുഞ്ഞ്",
    baby_crying: "കുഞ്ഞ് കരയുന്നു",
    baby_talking: "കുഞ്ഞ് സംസാരിക്കുന്നു",
    barking: "കുരയ്ക്കുന്നു",
    beep: "ബീപ്പ്",
    bell: "മണി",
    bird_squawk: "പക്ഷിക്കരച്ചിൽ",
    breathing: "ശ്വാസോച്ഛ്വാസം",
    buzz: "മൂളൽ",
    buzzer: "ബസർ",
    child: "കുട്ടി",
    child_crying: "കുട്ടി കരയുന്നു",
    child_laughing: "കുട്ടി ചിരിക്കുന്നു",
    child_talking: "കുട്ടി സംസാരിക്കുന്നു",
    child_whining: "കുട്ടി ചിണുങ്ങുന്നു",
    child_yelling: "കുട്ടി അലറുന്നു",
    children: "കുട്ടികൾ",
    children_talking: "കുട്ടികൾ സംസാരിക്കുന്നു",
    children_yelling: "കുട്ടികൾ അലറുന്നു",
    chiming: "മണിമുഴക്കുന്നു",
    clanging: "കിലുക്കുന്നു",
    clanking: "ലോഹത്തിന്റെ ശബ്ദം",
    click: "ക്ലിക്കുചെയ്യുക",
    clicking: "ക്ലിക്കു ചെയ്യുന്നു",
    clink: "കലമ്പൽ",
    clinking: "കിലുകിലുക്കം",
    cough: "ചുമ",
    dishes: "പാത്രങ്ങൾ",
    door: "വാതിൽ",
    footsteps: "ചവിട്ടുപടികൾ",
    gasp: "കിതയ്ക്കുക",
    groan: "ഞരക്കം",
    hiss: "സീൽക്കാരം",
    horn: "ഹോൺ",
    hum: "മൂളുക",
    inhaling: "ശ്വാസമെടുക്കുന്നു",
    laughter: "ചിരി",
    meow: "മ്യാവൂ",
    motorcycle: "മോട്ടോർ സൈക്കിൾ",
    music: "സംഗീതം",
    noise: "ശബ്ദം",
    nose_blowing: "മൂക്കുചീറ്റൽ",
    phone_ringing: "ഫോൺ ബെല്ലടിക്കുന്നു",
    popping: "nan",
    pounding: "പൊടിക്കുന്നു",
    printer: "പ്രിന്റർ",
    rattling: "ചിലമ്പൽ",
    ringing: "ചെയ്യുന്നു",
    rustling: "മർമ്മരം",
    scratching: "ചുരണ്ടുന്നു",
    screeching: "പരുക്കൻ ശബ്ദം",
    sigh: "നെടുവീർപ്പ്",
    singing: "പാടുന്നു",
    siren: "സൈറൺ",
    smack: "nan",
    sneezing: "തുമ്മുന്നു",
    sniffing: "മണക്കുന്നു",
    snorting: "ചീറുന്നു",
    squawking: "ആക്രോശിക്കുന്നു",
    squeak: "കാറൽ",
    static: "നിശ്ചലം",
    swallowing: "വിഴുങ്ങുന്നു",
    talking: "സംസാരിക്കുന്നു",
    tapping: "മെല്ലെ തട്ടുന്നു",
    throat_clearing: "മുരടനക്കുന്നു",
    thumping: "ഉച്ചത്തിൽ തട്ടുന്നു",
    tone: "റ്റോൺ",
    tones: "റ്റോണുകൾ",
    trill: "വിറയൽ ശബ്ദം",
    tsk: "nan",
    typewriter: "റ്റൈപ്പ് റൈറ്റർ",
    ugh: "ഉഹ്",
    wheezing: "വിമ്മിട്ടപ്പെടുന്നു",
    whispering: "അടക്കിപ്പറയുന്നു",
    whistling: "ചൂളമിടുന്നു",
    yawning: "കോട്ടുവായിടുന്നു",
    yelling: "ആക്രോശിക്കുന്നു",
  },
  bengali: {
    TV: "টিভি/দূরদর্শন",
    baby: "শিশু",
    baby_crying: "শিশু_কাঁদছে",
    baby_talking: "শিশু_কথা বলছে",
    barking: "ঘেউঘেউ",
    beep: "বিপ",
    bell: "ঘন্টা",
    bird_squawk: "পাখি_ডাকছে",
    breathing: "শ্বাসপ্রশ্বাস",
    buzz: "গুঞ্জন",
    buzzer: "ভেঁপু",
    child: "বাচ্চা",
    child_crying: "বাচ্চা_কাঁদছে",
    child_laughing: "বাচ্চা_হাসছে",
    child_talking: "বাচ্চা_কথা বলছে",
    child_whining: "বাচ্চা_ঘ্যানঘ্যান করছে",
    child_yelling: "বাচ্চা_চেঁচাচ্ছে",
    children: "বাচ্চারা",
    children_talking: "বাচ্চারা_কথা বলছে",
    children_yelling: "বাচ্চারা_চেঁচাচ্ছে",
    chiming: "টুংটাং",
    clanging: "ঠংঠং",
    clanking: "ঠংঠং",
    click: "ক্লিক",
    clicking: "ক্লিক করা",
    clink: "ঠুংঠুং",
    clinking: "ঠুংঠুং করা",
    cough: "কাশি",
    dishes: "বাসন",
    door: "দরজা",
    footsteps: "পদধ্বনি",
    gasp: "খাবি খাওয়া",
    groan: "গোঙানো",
    hiss: "হিস্\u200cহিস্\u200c",
    horn: "হর্ন",
    hum: "গুন্\u200cগুন্\u200c",
    inhaling: "শ্বাস নেওয়া",
    laughter: "হাসি",
    meow: "ম্যাও",
    motorcycle: "মোটরসাইকেল",
    music: "সঙ্গীত",
    noise: "আওয়াজ",
    nose_blowing: "নাক_ঝাড়ছে",
    phone_ringing: "ফোন_বাজছে",
    popping: "ফাটছে",
    pounding: "পিষছে",
    printer: "প্রিন্টার",
    rattling: "খড়খড়",
    ringing: "রিংরিং",
    rustling: "খসখস",
    scratching: "আঁচড় কাটছে",
    screeching: "চিৎকার করছে",
    sigh: "দীর্ঘশ্বাস",
    singing: "গাইছে",
    siren: "সাইরেন",
    smack: "ঠাস",
    sneezing: "হাঁচি",
    sniffing: "শুঁকছে",
    snorting: "নাসিকাধ্বনি",
    squawking: "কা কা করছে",
    squeak: "চিঁ চিঁ করছে",
    static: "অচলতড়িৎ",
    swallowing: "গিলছে",
    talking: "কথা বলছে",
    tapping: "ঠক ঠক করছে",
    throat_clearing: "গলা_খাঁকরাচ্ছে",
    thumping: "থপ থপ করছে",
    tone: "স্বর",
    tones: "স্বরগুলি",
    trill: "কম্পনধ্বনি",
    tsk: "চুকচুক",
    typewriter: "টাইপরাইটার",
    ugh: "ইস",
    wheezing: "শ্বাসকষ্ট",
    whispering: "ফিসফিস",
    whistling: "শিস",
    yawning: "হাই তুলছে",
    yelling: "চেঁচাচ্ছে",
  },
  sanskrit: {
    TV: "टि.वि.",
    baby: "शिशुः",
    baby_crying: "शिशोः रोदनम्",
    baby_talking: "कलभाषणम्",
    barking: "बुक्कनम्",
    beep: "बीप्-रवः",
    bell: "घण्टा",
    bird_squawk: "पक्षिणः कूजनम्",
    breathing: "श्वासोच्छ्वासः",
    buzz: "गुञ्जनम्",
    buzzer: "नादकम्",
    child: "बालः",
    child_crying: "बालस्य रोदनम्",
    child_laughing: "बालस्य हसनम्",
    child_talking: "बालस्य भाषणम्",
    child_whining: "बालस्य विलपनम्",
    child_yelling: "बालस्य कूजनम्",
    children: "बालाः",
    children_talking: "बालानां भाषणम्",
    children_yelling: "बालानां कूजनम्",
    chiming: "वादनम्",
    clanging: "ध्वननम्",
    clanking: "क्वणनम्",
    click: "क्लिक्",
    clicking: "क्लिक्-करणम्",
    clink: "शिञ्जनम्",
    clinking: "शिञ्जनम्",
    cough: "कासः",
    dishes: "पात्राणि",
    door: "द्वारम्",
    footsteps: "चरणम्",
    gasp: "निश्श्वसितम्",
    groan: "आर्तस्वरः",
    hiss: "फूत्करणम्",
    horn: "सूचनाध्वनिः",
    hum: "गुञ्जितम्",
    inhaling: "उच्छ्वसनम्",
    laughter: "हसनम्",
    meow: "मार्जारस्य कूजनम्",
    motorcycle: "द्विचक्रिका",
    music: "गीतम्",
    noise: "रवः",
    nose_blowing: "नासावमार्जनम्",
    phone_ringing: "दूरवाणि-शब्दः",
    popping: "उत्पुटनम्",
    pounding: "चूर्णनम्",
    printer: "उट्टङ्ककः",
    rattling: "रणनम्",
    ringing: "नदनम्",
    rustling: "पर्णस्वनम्",
    scratching: "क्षुरणम्",
    screeching: "आक्रन्दनम्",
    sigh: "निश्श्वासः",
    singing: "गायनम्",
    siren: "सैरन्-ध्वनिः",
    smack: "आस्फारणम्",
    sneezing: "क्षवणम्",
    sniffing: "क्लन्दनम्",
    snorting: "उच्छ्वसितम्",
    squawking: "कूजनम्",
    squeak: "रिङ्गणम्",
    static: "तटस्थम्",
    swallowing: "भक्षणम्",
    talking: "भाषितम्",
    tapping: "पटपटितम्",
    throat_clearing: "कण्टशोधितम्",
    thumping: "घट्टनम्",
    tone: "स्वरः",
    tones: "स्वराः",
    trill: "स्वरकम्पनम्",
    tsk: "धिक्",
    typewriter: "मुद्रकम्",
    ugh: "अलम्",
    wheezing: "प्रतिक्षुतम्",
    whispering: "कर्णेकथनम्",
    whistling: "गुञ्जनम्",
    yawning: "जृम्भितम्",
    yelling: "चित्करणम्",
  },
  marathi: {
    TV: "टीव्ही",
    baby: "बाळ",
    baby_crying: "बाळ_रडताना",
    baby_talking: "बाळ_बोलताना",
    barking: "भुंकताना",
    beep: "बीप",
    bell: "घंटा",
    bird_squawk: "पक्षाचा_कर्कश आवाज",
    breathing: "श्वासोच्छ्वास",
    buzz: "घोंघावणे",
    buzzer: "बझर",
    child: "मूल",
    child_crying: "मूल_रडताना",
    child_laughing: "मूल_हसताना",
    child_talking: "मूल_बोलताना",
    child_whining: "मूल_किरकिरताना",
    child_yelling: "मूल_किंचाळताना",
    children: "मुले",
    children_talking: "मुले_बोलताना",
    children_yelling: "मुले_किंचाळताना",
    chiming: "झंकार",
    clanging: "घणघाणाट",
    clanking: "खणखणाट",
    click: "टिकटिक",
    clicking: "टिकटिक होताना",
    clink: "किणकिण",
    clinking: "किणकिण होताना",
    cough: "खोकला",
    dishes: "पदार्थ",
    door: "दरवाजा",
    footsteps: "पावले",
    gasp: "धाप लागणे",
    groan: "कण्हणे",
    hiss: "फुत्कार",
    horn: "कर्णा",
    hum: "गुंजारव",
    inhaling: "श्वास घेताना",
    laughter: "हशा",
    meow: "म्यांव",
    motorcycle: "फटफटी",
    music: "संगीत",
    noise: "कलकलाट",
    nose_blowing: "नाक_शिंकरताना",
    phone_ringing: "फोन_वाजताना",
    popping: "फट आवाज",
    pounding: "कांडण",
    printer: "मुद्रण यंत्र",
    rattling: "खडखड",
    ringing: "घंटानाद",
    rustling: "सळसळ",
    scratching: "ओरखडताना",
    screeching: "खरखरताना",
    sigh: "नि:श्वास",
    singing: "गाताना",
    siren: "भोंगा",
    smack: "चापट",
    sneezing: "शिंकताना",
    sniffing: "हुंगताना",
    snorting: "उपहास",
    squawking: "कर्कश आवाज काढताना",
    squeak: "ची ची आवाज",
    static: "स्थिर",
    swallowing: "गिळताना",
    talking: "बोलताना",
    tapping: "टपटप",
    throat_clearing: "घसा_खाकरणे",
    thumping: "दणदण आवाज",
    tone: "स्वर",
    tones: "अनेक स्वर",
    trill: "कंपयुक्त आवाज",
    tsk: "नापसंतीचा उद्गार",
    typewriter: "टंकन यंत्र",
    ugh: "तिरस्काराचा उद्गार",
    wheezing: "घरघर",
    whispering: "कुजबूज करताना",
    whistling: "शिट्टी मारताना",
    yawning: "जांभई देताना",
    yelling: "किंचाळताना",
  },
  kannada: {
    TV: "ಟಿ.ವಿ.",
    baby: "ಮಗು",
    baby_crying: "ಮಗು_ಅಳುತ್ತಿರುವುದು",
    baby_talking: "ಮಗು_ಮಾತನಾಡುತ್ತಿರುವುದು",
    barking: "ಬೊಗಳುತ್ತಿರುವುದು",
    beep: "ಬೀಪ್",
    bell: "ಗಂಟೆ",
    bird_squawk: "ಪಕ್ಷಿಯ_ಕೂಗು",
    breathing: "ಉಸಿರಾಟ",
    buzz: "ಝೇಂಕಾರ",
    buzzer: "ಗುಂಜನ",
    child: "ಮಗು",
    child_crying: "ಮಗು_ಅಳುತ್ತಿರುವುದು",
    child_laughing: "ಮಗು_ನಗುತ್ತಿರುವುದು",
    child_talking: "ಮಗು_ಮಾತನಾಡುತ್ತಿರುವುದು",
    child_whining: "ಮಗು_ಕುಂಯ್ಗುಡುತ್ತಿರುವುದು",
    child_yelling: "ಮಗು_ಕಿರುಚುತ್ತಿರುವುದು",
    children: "ಮಕ್ಕಳು",
    children_talking: "ಮಕ್ಕಳು_ಮಾತನಾಡುತ್ತಿರುವುದು",
    children_yelling: "ಮಕ್ಕಳು_ಕಿರುಚಾಡುತ್ತಿರುವುದು",
    chiming: "ಕಿಂಕಿಣಿಸುವ ಸದ್ದು",
    clanging: "ಘಣಿಲ್ ಸದ್ದು",
    clanking: "ಝಣಝಣ ಸದ್ದು",
    click: "ಕ್ಲಿಕ್",
    clicking: "ಕ್ಲಿಕ್ಕಿಸುವ ಸದ್ದು",
    clink: "ಟಿಂಟಿಣಿ",
    clinking: "ಟಿಂಟಿಣಿಸುವ ಸದ್ದು",
    cough: "ಕೆಮ್ಮು",
    dishes: "ಪಾತ್ರೆಗಳು",
    door: "ಬಾಗಿಲು",
    footsteps: "ಹೆಜ್ಜೆ",
    gasp: "ಏದುಸಿರು",
    groan: "ಮುಲುಗು",
    hiss: "ಬುಸ್ಸ್",
    horn: "ಭೋಂಪು",
    hum: "ಗುನುಗು",
    inhaling: "ಉಚ್ಛ್ವಾಸ",
    laughter: "ನಗು",
    meow: "ಮಿಯಾಂವ್",
    motorcycle: "ಮೋಟಾರುವಾಹನ",
    music: "ಸಂಗೀತ",
    noise: "ಗದ್ದಲ",
    nose_blowing: "ಮೂಗು_ಒರೆಸಿಕೊಳ್ಳುತ್ತಿರುವುದು",
    phone_ringing: "ಫೋನ್_ರಿಂಗಣಿಸುತ್ತಿರುವುದು",
    popping: "ಪಾಪ್ ಸದ್ದು",
    pounding: "ಕುಟ್ಟುವ ಸದ್ದು",
    printer: "ಮುದ್ರಣಯಂತ್ರ",
    rattling: "ಲಟಲಟ ಸದ್ದು",
    ringing: "ರಿಂಗಣ",
    rustling: "ಮರ್ಮರ",
    scratching: "ಕೆರೆಯುತ್ತಿರುವುದು",
    screeching: "ಚೀತ್ಕಾರ",
    sigh: "ನಿಟ್ಟುಸಿರು",
    singing: "ಹಾಡುತ್ತಿರುವುದು",
    siren: "ಸೈರನ್ನು",
    smack: "ಚಪ್ಪರಿಕೆ",
    sneezing: "ಸೀನುತ್ತಿರುವುದು",
    sniffing: "ಮೂಸುತ್ತಿರುವುದು",
    snorting: "ಘೂಂಕರಿಸುತ್ತಿರುವುದು",
    squawking: "ಚೀರಲು ಸದ್ದು",
    squeak: "ಕಿಚುಗುಟ್ಟುತ್ತಿರುವುದು",
    static: "ನಿಶ್ಚಲ",
    swallowing: "ನುಂಗುತ್ತಿರುವುದು",
    talking: "ಮಾತನಾಡುತ್ತಿರುವುದು",
    tapping: "ತಟ್ಟುವ ಸದ್ದು",
    throat_clearing: "ಗಂಟಲು_ಸರಿಮಾಡಿಕೊಳ್ಳುತ್ತಿರುವುದು",
    thumping: "ಬಡಿತದ ಸದ್ದು",
    tone: "ಧ್ವನಿ",
    tones: "ಧ್ವನಿಗಳು",
    trill: "ನಡುಗು ಧ್ವನಿ",
    tsk: "ಪ್ಚ್",
    typewriter: "ಟೈಪ್ ರೈಟರ್",
    ugh: "ಛೆ",
    wheezing: "ಉಬ್ಬಸದ ಶಬ್ದ",
    whispering: "ಪಿಸುಗುಡುತ್ತಿರುವುದು",
    whistling: "ಸಿಳ್ಳೆ ಸದ್ದು",
    yawning: "ಆಕಳಿಸುತ್ತಿರುವುದು",
    yelling: "ಅರಚುತ್ತಿರುವುದು",
  },
  telugu: {
    TV: "టీ.వీ.",
    baby: "శిశువు",
    baby_crying: "శిశువు_ఏడవటం",
    baby_talking: "శిశువు_మాట్లాడటం",
    barking: "మొరగటం",
    beep: "బీప్",
    bell: "గంట",
    bird_squawk: "పిట్ట_అరుపు",
    breathing: "శ్వాసించటం",
    buzz: "బజ్",
    buzzer: "బజ్జర్",
    child: "బిడ్డ",
    child_crying: "బిడ్డ_ఏడవటం",
    child_laughing: "బిడ్డ_నవ్వటం",
    child_talking: "బిడ్డ_మాట్లాడటం",
    child_whining: "బిడ్డ_కూనిరాగాలు తీయటం",
    child_yelling: "బిడ్డ_అరవటం",
    children: "పిల్లలు",
    children_talking: "పిల్లలు_మాట్లాడటం",
    children_yelling: "పిల్లలు_అరవటం",
    chiming: "ధ్వనించటం",
    clanging: "గణగణమనడం",
    clanking: "లోహపు ధ్వనులు చేయటం",
    click: "క్లిక్",
    clicking: "క్లిక్\u200cమనటం",
    clink: "క్లింక్",
    clinking: "క్లింక్\u200cమనటం",
    cough: "దగ్గు",
    dishes: "పాత్రలు",
    door: "తలుపు",
    footsteps: "అడుగులు",
    gasp: "ఎగశ్వాస ",
    groan: "మూలుగు",
    hiss: "బుస్సుమను",
    horn: "హారన్",
    hum: "ఝంకారం",
    inhaling: "ఉచ్ఛ్వాసం",
    laughter: "నవ్వు",
    meow: "మియావ్",
    motorcycle: "మోటార్\u200cసైకిల్",
    music: "సంగీతం",
    noise: "శబ్దం",
    nose_blowing: "ముక్కు చీదటం",
    phone_ringing: "ఫోను మోగటం",
    popping: "పేలుడు శబ్దం",
    pounding: "కొట్టడం",
    printer: "ప్రింటర్",
    rattling: "కటకటమనటం",
    ringing: "మ్రోగటం",
    rustling: "గలగలమనటం",
    scratching: "గోకటం",
    screeching: "కీచుగా అరవటం",
    sigh: "నిట్టూర్పు",
    singing: "పాడటం",
    siren: "సైరన్",
    smack: "కొట్టటం",
    sneezing: "తుమ్మటం",
    sniffing: "వాసన పీల్చటం",
    snorting: "సకిలింత",
    squawking: "పక్షి శబ్దం",
    squeak: "కీచుమనటం",
    static: "విద్యుత్తు వల్ల శబ్దం",
    swallowing: "మింగటం",
    talking: "మాట్లాడటం",
    tapping: "తట్టటం",
    throat_clearing: "గొంతు సవరించుకోవటం",
    thumping: "గుద్దటం",
    tone: "స్వరం",
    tones: "స్వరాలు",
    trill: "వణికే గొంతు",
    tsk: "ప్చ్",
    typewriter: "టైప్\u200cరైటర్",
    ugh: "చిరాకు",
    wheezing: "గుర్రుమని శ్వాస",
    whispering: "గుసగుస",
    whistling: "ఈల వేయటం",
    yawning: "ఆవలింత",
    yelling: "కేకలు పెట్టటం",
  },
  sindhi: {
    TV: "टी.वी.",
    baby: "nan",
    baby_crying: "nan",
    baby_talking: "nan",
    barking: "nan",
    beep: "nan",
    bell: "nan",
    bird_squawk: "nan",
    breathing: "nan",
    buzz: "nan",
    buzzer: "nan",
    child: "nan",
    child_crying: "nan",
    child_laughing: "nan",
    child_talking: "nan",
    child_whining: "nan",
    child_yelling: "nan",
    children: "nan",
    children_talking: "nan",
    children_yelling: "nan",
    chiming: "nan",
    clanging: "nan",
    clanking: "nan",
    click: "nan",
    clicking: "nan",
    clink: "nan",
    clinking: "nan",
    cough: "nan",
    dishes: "nan",
    door: "nan",
    footsteps: "nan",
    gasp: "nan",
    groan: "nan",
    hiss: "nan",
    horn: "nan",
    hum: "nan",
    inhaling: "nan",
    laughter: "nan",
    meow: "nan",
    motorcycle: "nan",
    music: "nan",
    noise: "nan",
    nose_blowing: "nan",
    phone_ringing: "nan",
    popping: "nan",
    pounding: "nan",
    printer: "nan",
    rattling: "nan",
    ringing: "nan",
    rustling: "nan",
    scratching: "nan",
    screeching: "nan",
    sigh: "nan",
    singing: "nan",
    siren: "nan",
    smack: "nan",
    sneezing: "nan",
    sniffing: "nan",
    snorting: "nan",
    squawking: "nan",
    squeak: "nan",
    static: "nan",
    swallowing: "nan",
    talking: "nan",
    tapping: "nan",
    throat_clearing: "nan",
    thumping: "nan",
    tone: "nan",
    tones: "nan",
    trill: "nan",
    tsk: "nan",
    typewriter: "nan",
    ugh: "nan",
    wheezing: "nan",
    whispering: "nan",
    whistling: "nan",
    yawning: "nan",
    yelling: "nan",
  },
  bodo: {
    TV: "टि.भि.",
    baby: "गोरलै",
    baby_crying: "गोरलै_गाबदों",
    baby_talking: "गोरलै_रायलायदों",
    barking: "सोंदों",
    beep: "बिप",
    bell: "घन्टा",
    bird_squawk: "दाउ_गाबदों",
    breathing: "हां लादों",
    buzz: "थेर",
    buzzer: "थेरग्रा",
    child: "खुदिया",
    child_crying: "खुदिया_गाबदों",
    child_laughing: "खुदिया_मिनिदों",
    child_talking: "खुदिया_रायलायदों",
    child_whining: "खुदिया_खुद्रिदों",
    child_yelling: "खुदिया_होसिख्रावदों",
    children: "खुदियाफोर",
    children_talking: "खुदियाफोर_रायलायदों",
    children_yelling: "खुदियाफोर_होसिख्रावदों",
    chiming: "थिं थिं",
    clanging: "थं थं",
    clanking: "ख्रें ख्रें",
    click: "टिक टिक",
    clicking: "टिक टिक गाबदों",
    clink: "थुंथुं-थांथां",
    clinking: "थिं थिंं गाबदों",
    cough: "गुजुस्रेम",
    dishes: "थोरसिफोर",
    door: "दरजा",
    footsteps: "आगाननि सोदोब",
    gasp: "हांमा सुर",
    groan: "रोनाव",
    hiss: "फुस फुस",
    horn: "हर्न दाम",
    hum: "ब्रुं ब्रुं",
    inhaling: "हां लानाय",
    laughter: "मिनि",
    meow: "मेउ मेउ",
    motorcycle: "मटर साइकेल",
    music: "देंखो",
    noise: "दावराव",
    nose_blowing: "गन्थं गाबनाय",
    phone_ringing: "फ'न रिंदों",
    popping: "फ्रात ",
    pounding: "सौनाय",
    printer: "प्रिन्टार",
    rattling: "खख्र-खाख्रा",
    ringing: "रिंनाय",
    rustling: "रिब रिब",
    scratching: "खुरनाय",
    screeching: "होसिख्राव",
    sigh: "हांमा सुर",
    singing: "रोजाब",
    siren: "साइरेन",
    smack: "सोबा",
    sneezing: "हारसिउ",
    sniffing: "मोनामसु",
    snorting: "जुगुम",
    squawking: "बोराब",
    squeak: "सि सि खालाम",
    static: "दलर",
    swallowing: "मन'",
    talking: "रायलायदों",
    tapping: "बुज्रावदों",
    throat_clearing: "गोदोना_स्रां खालामदों",
    thumping: "सौदों",
    tone: "सुर",
    tones: "देंखोफोर",
    trill: "देंखो खम्फिनाय",
    tsk: "चि चि",
    typewriter: "टाइपरायटार",
    ugh: "उस",
    wheezing: "हां थोनो थोनो",
    whispering: "फुस फुस",
    whistling: "मुसुरदों",
    yawning: "सियायदों",
    yelling: "होख्रावदों",
  },
  assamese: {
    TV: "টি.ভি.",
    baby: "কেঁচুৱা",
    baby_crying: "কেঁচুৱা_কান্দিছে",
    baby_talking: "কেঁচুৱা_কথা পাতিছে",
    barking: "ভুকভুকনি",
    beep: "বিপ",
    bell: "ঘণ্টা",
    bird_squawk: "চৰাই_চী চিঁয়নি",
    breathing: "শ্বাসক্ৰিয়া",
    buzz: "গুঞ্জন",
    buzzer: "পেঁপা",
    child: "শিশু",
    child_crying: "শিশু_কান্দিছে",
    child_laughing: "শিশু_হাঁহিছে",
    child_talking: "শিশু_কথা পাতিছে",
    child_whining: "শিশু_থেনথেনাইছে",
    child_yelling: "শিশু_চিঞৰিছে",
    children: "শিশুসকল",
    children_talking: "শিশুসকল_কথা পাতিছে",
    children_yelling: "শিশুসকল_চিঞৰিছে",
    chiming: "ঝন ঝন",
    clanging: "ৰন জন",
    clanking: "ঘটং-মটং",
    click: "ক্লিক",
    clicking: "ক্লিক কৰা",
    clink: "ঠনঠন",
    clinking: "ঠনঠননি",
    cough: "কাহ",
    dishes: "কাঁহী",
    door: "দৰ্জা",
    footsteps: "পদধ্বনি",
    gasp: "ফোঁপনি",
    groan: "কেঁকনি",
    hiss: "ফোঁচ ফোঁচনি",
    horn: "হৰ্ণ",
    hum: "গুনগুন",
    inhaling: "উশাহ লোৱা",
    laughter: "হাঁহি",
    meow: "মেও মেও",
    motorcycle: "মটৰ চাইকেল",
    music: "সঙ্গীত",
    noise: "কোলাহল",
    nose_blowing: "নাক_ফোৰোহণি মাৰিছে",
    phone_ringing: "ফোন_বাজিছে",
    popping: "ফট কৰা",
    pounding: "খুন্দা",
    printer: "প্ৰিণ্টাৰ",
    rattling: "খটখটনি",
    ringing: "ৰিং কৰিছে",
    rustling: "ফুচফুচনি",
    scratching: "আঁচোৰিছে",
    screeching: "কুৰুলিয়াইছে",
    sigh: "হুমুনিয়াহ কাঢ়া",
    singing: "গাইছে",
    siren: "ছাইৰেণ",
    smack: "চৰিয়া",
    sneezing: "হাঁচি মাৰিছে",
    sniffing: "শুঙিছে",
    snorting: "ফোৰোহণি মাৰিছে",
    squawking: "কা কা কৰিছে",
    squeak: "চী চিঁয়নি কৰিছে",
    static: "স্থিৰ",
    swallowing: "গিলিছে",
    talking: "কথা পাতিছে",
    tapping: "টুকৰিয়াইছে",
    throat_clearing: "গল_খোলোচা কৰিছে",
    thumping: "ঢকিয়াইছে",
    tone: "স্বৰ",
    tones: "স্বৰসমূহ",
    trill: "স্বৰৰ কম্পন",
    tsk: "nan",
    typewriter: "টাইপৰাইটাৰ",
    ugh: "ইচ",
    wheezing: "সেহনি",
    whispering: "ফুচফুচাইছে",
    whistling: "সুহুৰি মাৰিছে",
    yawning: "হামিয়াইছে",
    yelling: "চিঞৰিছে",
  },
  tamil: {
    TV: "டிவி",
    baby: "குழந்தை",
    baby_crying: "குழந்தை_அழுகிறது",
    baby_talking: "குழந்தை_பேசுகிறது",
    barking: "நாய்_குரைக்கிறது",
    beep: "பீப்",
    bell: "மணி",
    bird_squawk: "பறவை_கத்தும்_சப்தம்",
    breathing: "மூச்சுவிடும்_ஓசை",
    buzz: "தேனீ_ஓசை",
    buzzer: "பஸ்ஸர்_ஓசை",
    child: "குழந்தை",
    child_crying: "குழந்தை_அழுகிறது",
    child_laughing: "குழந்தை_சிரிக்கிறது",
    child_talking: "குழந்தை_பேசுகிறது",
    child_whining: "குழந்தை_புலம்புகிறது",
    child_yelling: "குழந்தை_கத்துகிறது",
    children: "குழந்தைகள்",
    children_talking: "குழந்தைகள்_பேசுகிறார்கள்",
    children_yelling: "குழந்தைகள்_கத்துகிறார்கள்",
    chiming: "கடிகாரம்_அடிக்கிறது",
    clanging: "nan",
    clanking: "nan",
    click: "கிளிக்",
    clicking: "கிளிக்_ஓசை",
    clink: "nan",
    clinking: "nan",
    cough: "இருமல்",
    dishes: "பாத்திரங்கள்",
    door: "கதவு",
    footsteps: "காலடி",
    gasp: "மூச்சுவாங்குகிறது",
    groan: "முனகல்",
    hiss: "nan",
    horn: "ஹாரன்",
    hum: "nan",
    inhaling: "மூச்சிழுத்தல்",
    laughter: "சிரிப்பு",
    meow: "மியாவ்",
    motorcycle: "மோட்டர்சைக்கிள்",
    music: "இசை",
    noise: "இரைச்சல்",
    nose_blowing: "மூக்கு_உறிஞ்சுதல்",
    phone_ringing: "போன்_அடிக்கிறது",
    popping: "nan",
    pounding: "nan",
    printer: "பிரிண்டர்",
    rattling: "nan",
    ringing: "மணி_அடிக்கிறது",
    rustling: "சலசலப்பு",
    scratching: "கீறல்",
    screeching: "கிரீச்",
    sigh: "பெருமூச்சு",
    singing: "பாட்டு",
    siren: "சைரன்",
    smack: "nan",
    sneezing: "தும்மல்",
    sniffing: "மோப்பம்",
    snorting: "nan",
    squawking: "nan",
    squeak: "nan",
    static: "nan",
    swallowing: "விழுங்கும்_ஓசை",
    talking: "பேச்சு",
    tapping: "தட்டுதல்",
    throat_clearing: "nan",
    thumping: "nan",
    tone: "nan",
    tones: "nan",
    trill: "nan",
    tsk: "nan",
    typewriter: "nan",
    ugh: "nan",
    wheezing: "nan",
    whispering: "nan",
    whistling: "nan",
    yawning: "nan",
    yelling: "nan",
  },
  santali: {
    TV: "ᱴᱤᱹ ᱵᱤ ",
    baby: "ᱵᱟᱞᱮ ᱜᱤᱫᱽᱨᱟᱹ ",
    baby_crying: "ᱵᱟᱞᱮ ᱜᱤᱫᱽᱨᱟᱹᱭ_ᱨᱟᱜ ᱮᱫᱟ",
    baby_talking: "nan",
    barking: "ᱥᱮᱛᱟᱭ ᱵᱷᱩᱜ ᱮᱫᱟ",
    beep: "nan",
    bell: "ᱜᱷᱟᱹᱱᱴᱤ",
    bird_squawk: "ᱪᱮᱸᱲᱮ_ᱨᱟᱜ",
    breathing: "ᱥᱟᱦᱮᱸᱫ ",
    buzz: "nan",
    buzzer: "nan",
    child: "ᱜᱤᱫᱽᱨᱟᱹ ",
    child_crying: "ᱜᱤᱫᱽᱨᱟᱹᱭ_ᱨᱟᱜ ᱮᱫᱟ",
    child_laughing: "ᱜᱤᱫᱽᱨᱟᱹᱭ_ᱞᱟᱸᱫᱟᱭᱮᱫᱟ",
    child_talking: "nan",
    child_whining: "ᱜᱤᱫᱽᱨᱟᱹᱭ_ᱨᱚᱫᱚᱱ ᱠᱟᱱᱟ ",
    child_yelling: "nan",
    children: "ᱜᱤᱫᱽᱨᱟᱹ ᱠᱚ",
    children_talking: "ᱜᱤᱫᱽᱨᱟᱹ ᱠᱚ_ᱨᱚᱲᱮᱫ ᱠᱟᱱᱟ ",
    children_yelling: "nan",
    chiming: "nan",
    clanging: "nan",
    clanking: "nan",
    click: "ᱠᱞᱤᱠ",
    clicking: "nan",
    clink: "nan",
    clinking: "nan",
    cough: "ᱠᱷᱩᱜ",
    dishes: "ᱛᱷᱟᱹᱨᱤ ᱠᱚ ",
    door: "ᱫᱩᱣᱟᱹᱨ ",
    footsteps: "nan",
    gasp: "nan",
    groan: "nan",
    hiss: "nan",
    horn: "nan",
    hum: "nan",
    inhaling: "nan",
    laughter: "nan",
    meow: "nan",
    motorcycle: "nan",
    music: "nan",
    noise: "nan",
    nose_blowing: "nan",
    phone_ringing: "nan",
    popping: "nan",
    pounding: "nan",
    printer: "nan",
    rattling: "nan",
    ringing: "nan",
    rustling: "nan",
    scratching: "nan",
    screeching: "nan",
    sigh: "nan",
    singing: "nan",
    siren: "nan",
    smack: "nan",
    sneezing: "nan",
    sniffing: "nan",
    snorting: "nan",
    squawking: "nan",
    squeak: "nan",
    static: "nan",
    swallowing: "nan",
    talking: "nan",
    tapping: "nan",
    throat_clearing: "nan",
    thumping: "nan",
    tone: "ᱟᱲᱟᱝ  ",
    tones: "ᱟᱲᱟᱝ ᱠᱚ",
    trill: "ᱛᱟᱨᱠᱚ ᱥᱟᱰᱮ ",
    tsk: "nan",
    typewriter: "ᱴᱟᱭᱤᱯ ᱨᱟᱭᱴᱟᱨ",
    ugh: "ᱩᱦ",
    wheezing: "ᱩᱫᱩᱨ",
    whispering: "ᱯᱷᱟᱥᱟᱨ ᱯᱷᱩᱥᱩᱨ",
    whistling: "ᱜᱚᱞ/ᱣᱩᱭᱥᱮᱞ",
    yawning: "ᱟᱸᱜᱚᱵ ᱮᱫᱟᱭ",
    yelling: "nan",
  },
  odia: {
    TV: "ଟିଭି",
    baby: "ଶିଶୁ/ପିଲା",
    baby_crying: "ଶିଶୁ କାନ୍ଦୁଛି",
    baby_talking: "ପିଲା କହୁଛି",
    barking: "ଭୁକୁଛି",
    beep: "ବିପ୍\u200c",
    bell: "ଘଣ୍ଟି",
    bird_squawk: "ପକ୍ଷୀ ଚିତ୍କାର",
    breathing: "ଶ୍ୱାସକ୍ରିୟା",
    buzz: "ଗୁଞ୍ଜନ",
    buzzer: "ଘଣ୍ଟି",
    child: "ପିଲା",
    child_crying: "ପିଲା କାନ୍ଦୁଛି",
    child_laughing: "ପିଲା ହସୁଛି",
    child_talking: "ପିଲା କହୁଛି",
    child_whining: "ପିଲାର କରୁଣ କ୍ରନ୍ଦନ",
    child_yelling: "ପିଲାର ଚିତ୍କାର",
    children: "ପିଲାମାନେ",
    children_talking: "ପିଲାମାନେ କଥାହେଉଛନ୍ତି",
    children_yelling: "ପିଲାମାନେ ଚିତ୍କାର କରୁଛନ୍ତି",
    chiming: "ଘଣ୍ଟାଘ୍ୱନି",
    clanging: "ଠଣ୍\u200c ଠଣ୍\u200c ଶବ୍ଦ",
    clanking: "ଝଣ୍\u200c ଝଣ୍\u200c ଶବ୍ଦ",
    click: "ଟିକ୍\u200c ଟିକ୍\u200c ଶବ୍ଦ",
    clicking: "ଟିକ୍\u200c ଟିକ୍\u200c ଶବ୍ଦ କରୁଛି",
    clink: "ଝଣ୍\u200c ଝଣ୍\u200c ଶବ୍ଦ",
    clinking: "ଝଣ୍\u200c ଝଣ୍\u200c ଶବ୍ଦ କରୁଛି",
    cough: "କାଶ",
    dishes: "ବାସନ",
    door: "କବାଟ",
    footsteps: "ପାଦଶବ୍ଦ",
    gasp: "ଧଇଁସଇଁ ହେବା",
    groan: "ଯନ୍ତ୍ରଣାରେ ଚିତ୍କାର",
    hiss: "ସୁ ସୁ ଶବ୍ଦ",
    horn: "ହର୍ନ",
    hum: "ଗୁଣୁଗୁଣୁ ଶବ୍ଦ",
    inhaling: "ଶ୍ୱାସ ନେବା",
    laughter: "ହାସ୍ୟଧ୍ୱନି",
    meow: "ମ୍ୟାଉଁ ମ୍ୟାଉଁ ଶବ୍ଦ",
    motorcycle: "ମୋଟରସାଇକେଲ",
    music: "ସଙ୍ଗୀତ",
    noise: "କୋଳାହଳ",
    nose_blowing: "ନାକ ସଫାକରିବା",
    phone_ringing: "ଫୋନ୍\u200c ବାଜିବା",
    popping: "ଫୁଟିବା",
    pounding: "ଗୁଣ୍ଡକରିବା",
    printer: "ପ୍ରିଣ୍ଟର",
    rattling: "ଖଡ଼୍\u200c ଖଡ଼୍\u200c ଶବ୍ଦ",
    ringing: "ବାଜିବା",
    rustling: "ଖସ୍\u200c ଖସ୍\u200c ଶବ୍ଦ",
    scratching: "ଆଞ୍ଚୁଡ଼ିବା / ରାମ୍ପୁଡ଼ିବା",
    screeching: "କର୍କଶ ଓ ଉଚ୍ଚ ସ୍ୱରରେ ଚିତ୍କାର",
    sigh: "ଦୀର୍ଘଶ୍ୱାସ",
    singing: "ଗାଇବା",
    siren: "ସାଇରନ୍\u200c",
    smack: "ଚାପୁଡ଼ା",
    sneezing: "ଛିଙ୍କିବା",
    sniffing: "ଶୁଙ୍ଘିବା",
    snorting: "ଖିଙ୍କାରିଲା ପରି ଶବ୍ଦ",
    squawking: "ପକ୍ଷୀମାନଙ୍କର କର୍କଶ ସ୍ୱର",
    squeak: "ଚିଁ ଚିଁ ଶବ୍ଦ",
    static: "ସ୍ଥିର",
    swallowing: "ଗିଳିପକାଇବା",
    talking: "କହିବା",
    tapping: "ଠକ୍\u200cଠକ୍\u200c ଶବ୍ଦ",
    throat_clearing: "ଗଳା ସଫାକରିବା",
    thumping: "ଧଡ୍\u200c ଧଡ଼୍\u200c କରିବା",
    tone: "ସ୍ୱର",
    tones: "ସ୍ୱରଗୁଡ଼ିକ",
    trill: "କମ୍ପିତ ସ୍ୱର",
    tsk: "ଚ୍\u200c ଚ୍\u200c",
    typewriter: "ଟାଇପରାଇଟର",
    ugh: "ଛିଃ",
    wheezing: "ସଁ ସଁ ନିଶ୍ୱାସ",
    whispering: "ଫୁସ୍\u200cଫୁସ୍\u200c କରି କହିବା",
    whistling: "ସିଟି ମାରିବା",
    yawning: "ହାଇ ମାରିବା",
    yelling: "ପାଟି କରିବା",
  },
  hindi: {
    TV: "टी.वी.",
    baby: "शिशु",
    baby_crying: "शिशु रो रहा है",
    baby_talking: "शिशु बोल रहा है",
    barking: "भौंकना",
    beep: "बीप",
    bell: "घंटी",
    bird_squawk: "पक्षी की चहचहाहट",
    breathing: "साँस लेना",
    buzz: "गुंजन",
    buzzer: "बज़र",
    child: "बच्चा",
    child_crying: "बच्चा रो रहा है",
    child_laughing: "बच्चा हँस रहा है",
    child_talking: "बच्चा बोल रहा है",
    child_whining: "बच्चा रिरिया रहा है",
    child_yelling: "बच्चा चिल्ला रहा है",
    children: "बच्चे",
    children_talking: "बच्चे बोल रहे हैं",
    children_yelling: "बच्चे चिल्ला रहे हैं",
    chiming: "टनटनाहट",
    clanging: "ठनठनाहट",
    clanking: "झनझनाहट",
    click: "क्लिक",
    clicking: "क्लिक करना",
    clink: "झंकार",
    clinking: "झंकार की आवाज़",
    cough: "खाँसी",
    dishes: "बर्तन",
    door: "दरवाज़ा",
    footsteps: "कदम",
    gasp: "हाँफना",
    groan: "कराहना",
    hiss: "फुफकारना",
    horn: "हॉर्न",
    hum: "गुनगुनाना",
    inhaling: "साँस लेना",
    laughter: "हँसी",
    meow: "म्याऊँ",
    motorcycle: "मोटरसाइकिल",
    music: "संगीत",
    noise: "शोर",
    nose_blowing: "नाक साफ करना",
    phone_ringing: "फोन बजने की आवाज",
    popping: "फूटने की आवाज",
    pounding: "पीसना",
    printer: "प्रिंटर",
    rattling: "खड़खड़ाहट",
    ringing: "खनखनाहट",
    rustling: "सरसराहट",
    scratching: "खरोंचना",
    screeching: "चीत्कार मारना",
    sigh: "आह भरना",
    singing: "गाना",
    siren: "साइरन",
    smack: "चपत लगाना",
    sneezing: "छींकना",
    sniffing: "नाक सुड़कना",
    snorting: "नासिका ध्वनि",
    squawking: "कर्कश आवाज करना",
    squeak: "चीं चीं की आवाज करना",
    static: "(फोन, टी.वी. आदि की)कर्कश आवाज",
    swallowing: "निगलना",
    talking: "बात करना",
    tapping: "टप टप की आवाज",
    throat_clearing: "गला खखारना",
    thumping: "थपथपाहट",
    tone: "स्वर",
    tones: "कई स्वर",
    trill: "कंपनमय आवाज",
    tsk: "च् च्",
    typewriter: "टाइपराइटर",
    ugh: "छिः",
    wheezing: "घरघराहट",
    whispering: "फुसफुसाहट",
    whistling: "सीटी की आवाज",
    yawning: "उबासी लेना",
    yelling: "चिल्लाना",
  },
  english: {
    TV: "TV",
    baby: "baby",
    baby_crying: "baby_crying",
    baby_talking: "baby_talking",
    barking: "barking",
    beep: "beep",
    bell: "bell",
    bird_squawk: "bird_squawk",
    breathing: "breathing",
    buzz: "buzz",
    buzzer: "buzzer",
    child: "child",
    child_crying: "child_crying",
    child_laughing: "child_laughing",
    child_talking: "child_talking",
    child_whining: "child_whining",
    child_yelling: "child_yelling",
    children: "children",
    children_talking: "children_talking",
    children_yelling: "children_yelling",
    chiming: "chiming",
    clanging: "clanging",
    clanking: "clanking",
    click: "click",
    clicking: "clicking",
    clink: "clink",
    clinking: "clinking",
    cough: "cough",
    dishes: "dishes",
    door: "door",
    footsteps: "footsteps",
    gasp: "gasp",
    groan: "groan",
    hiss: "hiss",
    horn: "horn",
    hum: "hum",
    inhaling: "inhaling",
    laughter: "laughter",
    meow: "meow",
    motorcycle: "motorcycle",
    music: "music",
    noise: "noise",
    nose_blowing: "nose_blowing",
    phone_ringing: "phone_ringing",
    popping: "popping",
    pounding: "pounding",
    printer: "printer",
    rattling: "rattling",
    ringing: "ringing",
    rustling: "rustling",
    scratching: "scratching",
    screeching: "screeching",
    sigh: "sigh",
    singing: "singing",
    siren: "siren",
    smack: "smack",
    sneezing: "sneezing",
    sniffing: "sniffing",
    snorting: "snorting",
    squawking: "squawking",
    squeak: "squeak",
    static: "static",
    swallowing: "swallowing",
    talking: "talking",
    tapping: "tapping",
    throat_clearing: "throat_clearing",
    thumping: "thumping",
    tone: "tone",
    tones: "tones",
    trill: "trill",
    tsk: "tsk",
    typewriter: "typewriter",
    ugh: "ugh",
    wheezing: "wheezing",
    whispering: "whispering",
    whistling: "whistling",
    yawning: "yawning",
    yelling: "yelling",
  },
};
