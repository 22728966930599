export const colorArray = [
  {
    colors: "rgba(6, 190, 108, 0.4)",
  },
  {
    colors: "rgba(253, 127, 35, 0.5)",
  },
  {
    colors: "rgba(126, 211, 40, 0.4)",
  },
  {
    colors: "rgba(146, 45, 136, 0.6)",
  },
];
