import API from "../../../api";
import ENDPOINTS from "../../../../config/apiendpoint";
import C from "../../../constants";

export default class RemoveProjectMemberAPI extends API {
  constructor(projectId, userId, timeout = 2000) {
    super("POST", timeout, false);
    this.type = C.REMOVE_PROJECT_MEMBER;
    this.projectId = projectId;
    this.userId = userId;
    this.endpoint = `${super.apiEndPointAuto()}${
      ENDPOINTS.project
    }${projectId}/remove_project_members/`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
      this.report = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return {
      user_id: [this.userId],
    };
  }

  getHeaders() {
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report;
  }
}
